/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif;
}

.body1 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.body2 {
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.17px;
}

h6 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
}

h5 {
  font-size: 24px;
  line-height: 32.02px;
}

.card-container {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer/Edge */
}

.card-container::-webkit-scrollbar {
  display: none; /* For Webkit browsers */
}
.caption { 
  font-size: 12px;
  line-height: 166%;
  letter-spacing: 0.4px;
}