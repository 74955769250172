@tailwind base;
@tailwind components;
@tailwind utilities;

/* button */
.button-primary.MuiButton-root {
  @apply normal-case text-white bg-mui-primary-blue-light dark:bg-mui-primary-blue-dark;
  &:disabled {
    @apply text-mui-black-38 dark:text-mui-white-38 dark:bg-mui-white-12;
  }
  &:hover {
    @apply dark:bg-mui-blue-hover-dark;
  }
}
.button-normalcase.MuiButton-root {
  @apply normal-case text-mui-primary-blue-light dark:text-mui-primary-blue-dark;
}

.button-danger.MuiButton-root {
  @apply normal-case text-white bg-mui-primary-red-light dark:bg-text-error-main;
  &:disabled {
    @apply text-mui-black-38 dark:text-mui-white-38 dark:bg-mui-white-12;
  }
  &:hover {
    @apply dark:bg-mui-primary-hover-red;
  }
}

.button-loading .MuiCircularProgress-root {
  @apply text-white;
}

.button-outlined.MuiButton-root.MuiButton-outlined {
  @apply normal-case text-mui-gray border-mui-black-50 dark:border-mui-white-50 dark:text-mui-light-gray;
  &:hover {
    @apply bg-mui-gray-04 dark:bg-mui-black-04;
  }
  &:disabled {
    @apply border-mui-black-12 text-mui-black-38 dark:text-mui-white-38 dark:border-mui-white-12;
  }
}

.button-submit.MuiButton-root {
  @apply normal-case bg-mui-gray text-white dark:bg-mui-light-gray dark:text-mui-black-87;
  &:disabled {
    @apply dark:text-mui-white-38 dark:bg-mui-white-12;
  }
  &:hover {
    @apply bg-mui-dark-grey dark:bg-mui-white-gray;
  }
}

.button-link.MuiButtonBase-root {
  @apply normal-case text-mui-black-60;
}

/* icon button */
.cross-icon.MuiIconButton-root {
  @apply text-black dark:text-white;
}
/* toggle button group */
.togglegroup-border.MuiToggleButtonGroup-root {
  @apply border border-mui-black-56 dark:border-mui-white-56;
}

/* toggle button */
.category-button.MuiToggleButton-root {
  @apply normal-case text-mui-black-87 tracking-[0.46px] dark:text-white border-mui-black-12 dark:border-mui-white-12;
  &:hover {
    @apply dark:bg-mui-black-04;
  }
}
.category-button.MuiToggleButton-root.Mui-selected {
  @apply bg-mui-black-08 dark:bg-mui-white-16;
}

.no-border.MuiButtonBase-root {
  @apply border-0 border-r border-r-mui-black-12 dark:border-r-mui-white-12;
}
.right-border.MuiButtonBase-root.MuiToggleButtonGroup-lastButton {
  @apply border-r-0;
}
/* input text */
.outlined-input .MuiOutlinedInput-root fieldset {
  @apply border-mui-black-23 dark:border-mui-white-23;
}

.outlined-input .MuiOutlinedInput-root:hover fieldset {
  @apply dark:border-mui-primary-blue-dark;
}
/* .outlined-input .MuiOutlinedInput-root:hover fieldset {
  @apply border-mui-black-23 dark:border-mui-white-23;
} */

.outlined-input .MuiOutlinedInput-root.Mui-focused fieldset {
  @apply border-mui-primary-blue-light dark:border-mui-primary-blue-dark;
}

.outlined-input .MuiInputBase-input {
  @apply dark:text-white;
}

.rounded-textfield .MuiInputBase-root {
  @apply rounded-full;
}

.labelText .MuiInputLabel-root {
  @apply text-mui-black-38 dark:text-mui-white-38;
}

.labelText .MuiInputLabel-root.Mui-focused {
  @apply text-mui-black-60 dark:text-mui-white-70;
}
/* select dropdown */
.dropdown-menulist.MuiPaper-root {
  @apply dark:text-white dark:bg-background/paper-elevation-8;
}
.standard-select .MuiSelect-standard {
  @apply dark:text-mui-white-70;
}
.standard-select .MuiSelect-iconStandard {
  @apply dark:text-mui-white-56;
}
.select-input.MuiInputBase-root.MuiInput-underline::before {
  @apply dark:border-b-mui-white-42;
  &:hover {
    @apply dark:border-b-mui-white-42;
  }
}
.select-input.MuiInputBase-root.MuiInput-underline::after {
  @apply dark:border-b-mui-white-42;
}

.dropdown-icon .MuiSelect-icon {
  @apply top-1/2 transform -translate-y-1/2;
}
/* menu */
/* home menu */
.menulist-home .MuiMenu-paper {
  @apply text-mui-black-87 dark:text-white dark:bg-background/paper-elevation-16;
}
.submenu-item.MuiMenuItem-root {
  @apply w-[228px] flex justify-between py-[10px] px-4;
  &:hover {
    @apply bg-mui-black-08;
  }
}
.nested-menulist-home .MuiMenu-paper {
  @apply text-mui-black-87 dark:text-white ms-6 dark:bg-background/paper-elevation-16;
  left: 227px !important;

  @media (max-width: 640px) {
    left: 0px !important;
  }
}

/* menulist */
.menulist-item-custom.MuiMenuItem-root {
  @apply rounded;
  &:hover {
    @apply dark:bg-mui-light-blue;
  }
}
.menulist-item-custom.Mui-selected {
  @apply dark:bg-mui-light-blue;
}

.disable-hover.MuiMenuItem-root {
  @apply pointer-events-none;
  &:hover {
    @apply bg-transparent;
  }
}

/* dialog */
.dialog-model .MuiDialog-paper {
  @apply shadow w-modal-mobile sm:w-modal-sm md:w-modal-md lg:w-modal-lg rounded-lg p-6 bg-white dark:bg-background/paper-elevation-16;
}

.dialog-title.MuiDialogTitle-root {
  @apply text-mui-black-87 dark:text-white p-0 pb-4;
}

.dialog-content.MuiDialogContent-root {
  @apply p-0;
}

.dialog-text.MuiDialogContentText-root {
  @apply text-mui-black-87 dark:text-white;
}

.dialog-buttons.MuiDialogActions-root {
  @apply flex justify-between p-0 pt-6;
}

/* badge */
.blue-badge .MuiBadge-badge {
  @apply bg-mui-primary-blue-light text-white;
}
.default-badge .MuiBadge-badge {
  @apply bg-mui-black-12 text-mui-black-38 dark:bg-mui-white-12 dark:text-mui-white-38;
}

.tool-loader .MuiAccordion-root {
  @apply bg-transparent shadow-none;
}

.tool-loader .MuiButtonBase-root.MuiAccordionSummary-root {
  @apply px-0 min-h-9;
}

.tool-loader .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  @apply min-h-9; 
}

.tool-loader .MuiAccordionSummary-content {
  @apply my-0;
}

.tool-loader .MuiAccordionSummary-content.Mui-expanded {
  @apply my-0 mx-0;
}

.tool-loader .MuiAccordionDetails-root {
  @apply px-4 py-0;
}

.tool-loader .animation-fast.MuiCircularProgress-root {
  animation-duration: 0.8s;
}
